.UserProfileMenu-module__iN7Dqa__user-profile-menu {
  border: 1px solid var(--line-line);
  background-color: var(--bg-block);
  width: 140px;
  color: var(--text-primary);
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  display: flex;
  position: absolute;
  top: 80px;
  right: 30px;
}

.UserProfileMenu-module__iN7Dqa__user-profile-menu__avatar {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  overflow: hidden;
}

.UserProfileMenu-module__iN7Dqa__user-profile-menu__text-wrapper {
  padding: 5px 20px;
}

.UserProfileMenu-module__iN7Dqa__user-profile-menu__text-wrapper:nth-last-child(2n+2) {
  border-bottom: 1px solid var(--line-line);
  padding-bottom: 10px;
}

.UserProfileMenu-module__iN7Dqa__user-profile-menu__text-wrapper:nth-last-child(3), .UserProfileMenu-module__iN7Dqa__user-profile-menu__text-wrapper:first-child {
  padding-top: 10px;
}

.UserProfileMenu-module__iN7Dqa__user-profile-menu__text-wrapper:last-child {
  padding: 10px 20px;
}

.UserProfileMenu-module__iN7Dqa__user-profile-menu__text {
  width: 100%;
  height: 30px;
  color: var(--text-primary);
  align-items: center;
  display: flex;
}

.UserProfileMenu-module__iN7Dqa__user-profile-menu__text:hover {
  cursor: pointer;
  color: var(--text-link-active);
}

@media (max-width: 660px) {
  .UserProfileMenu-module__iN7Dqa__user-profile-menu {
    z-index: 1;
    top: 85px;
    right: 10px;
  }
}

.TagHeader-module__y_KKlq__tag-header {
  color: var(--text-primary);
  justify-content: flex-start;
  margin-bottom: 16px;
  display: flex;
  position: relative;
}

.TagHeader-module__y_KKlq__tag-header__logo-wrapper {
  border: 1px solid var(--line-line);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 80px;
  min-width: 80px;
  height: 80px;
  margin: 0 16px 0 0;
  padding: 8px;
  display: flex;
  overflow: hidden;
}

.TagHeader-module__y_KKlq__tag-header__logo {
  border-radius: 50%;
  position: absolute;
}

.TagHeader-module__y_KKlq__tag-header__text-block {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.TagHeader-module__y_KKlq__tag-header__subtitle {
  color: var(--text-secondary);
  font-style: italic;
}

.TagHeader-module__y_KKlq__tag-header__name {
  margin-bottom: 8px;
}

.TagHeader-module__y_KKlq__tag-header__number, .TagHeader-module__y_KKlq__tag-header__position {
  color: var(--text-secondary);
}

.TagHeader-module__y_KKlq__tag-header__last-five {
  gap: 4px;
  display: flex;
}

.TagHeader-module__y_KKlq__tag-header__last-five-item {
  border-radius: 50%;
  width: 8px;
  height: 8px;
}

.TagHeader-module__y_KKlq__tag-header__last-five-item--win {
  background-color: var(--icons-green);
}

.TagHeader-module__y_KKlq__tag-header__last-five-item--lose {
  background-color: var(--icons-red);
}

.TagHeader-module__y_KKlq__tag-header__last-five-item--draw {
  background-color: var(--icons-gray);
}

.TagHeader-module__y_KKlq__tag-header span:first-letter {
  text-transform: uppercase;
}

.TagHeader-module__y_KKlq__tag-header__go-to-admin {
  width: 100px;
  position: absolute;
  top: 10px;
  right: 10px;
}

@media (max-width: 660px) {
  .TagHeader-module__y_KKlq__tag-header__go-to-admin {
    margin-left: auto;
    position: static;
  }
}

.ButtonIntersection-module__J4d8ka__button-intersection {
  width: 100%;
}

